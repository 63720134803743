import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import WebDevelopmentServicesMain from "../components/WebDevelopmentServicesMain"

const WebsiteDevelopment = () => {
  const keyword = "Website Development Services"
  return (
    <Layout>
      <Seo
        noTitlePrefix={true}
        title={`${keyword} | Snappy Web Design`}
        description={`Looking for professional ${keyword}? Snappy Web Design makes web design and development easy for businesses.Rank higher on Google, lower your bounce rate. Snappy Web Design`}
        rating={true}
      />
      <WebDevelopmentServicesMain keyword={keyword} />
    </Layout>
  )
}

export default WebsiteDevelopment
