import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import undrawn from "../assets/images/undraw.svg"

import ButtonArrow from "./ButtonArrow"
import revolutionBackground from "../assets/images/repeatingBackground.svg"
import contactbg from "../assets/images/webb.png"

import {
  FaLightbulb,
  FaBolt,
  FaGem,
  FaWrench,
  FaHandHoldingUsd,
  FaHandPointUp,
  FaBloggerB,
  FaCameraRetro,
  FaGlobe,
  FaCommentDots,
  FaStore,
  FaHome,
  FaBlog,
  FaArchway,
  FaPhotoVideo,
  FaTicketAlt,
  FaChartArea,
  FaTools,
  FaArrowRight,
} from "react-icons/fa"

const dark = "#1c8460"
const primary = "#26a27b"

const BoxStyling = styled.div`
  border-radius: 0.5rem;
  height: 15em;
  padding: 1.5rem;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  border-color: #f4f5f7;
  transition-duration: 0.3s;
  text-align: center;
  border-top: 2px solid ${(props) => props.theme.primary};
  &:hover {
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  p {
    color: black;
  }
`

const useStyles = makeStyles((theme) => ({
  h1: {
    fontSize: "3.75rem",
    letterSpacing: ".05rem",
    fontWeight: 500,
    color: dark,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.75rem",
    },
  },
  h2: {
    paddingTop: "1rem",
    paddingBottom: "2rem",

    fontSize: "1.25rem",
    fontWeight: 400,
    lineHeight: "2.25rem",
    color: theme.palette.common.muted,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
      lineHeight: "1.75rem",
    },
  },
  headerCtr: {
    backgroundColor: "rgba(247,249,252)",
    borderRadius: "0 0 100% 0",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0 0 81% 0",
    },
  },
  headerSection: {
    padding: "6rem 8rem",
    [theme.breakpoints.down("lg")]: {
      padding: "6rem 4rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "4rem 2.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem",
    },
  },
  contactBtn: {
    textTransform: "capitalize",
    backgroundColor: "#ffff",
    fontSize: "1.15rem",
    width: "80%",
    padding: ".5rem 0",
    transition: ".4s",

    marginBottom: "2rem",
    "&:hover": {
      backgroundColor: primary,
      color: "#FFF",
      "& $arrowIco": {
        transform: "translate(1em)",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
    },
  },
  arrowIco: {
    marginLeft: 10,
    transition: "0.4s",
  },
  comicContainer: {
    height: 500,
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: 300,
      height: 300,
    },
  },
  comic: {
    height: "100%",
    position: "absolute",
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  servicesHeader: {
    fontSize: "1.5rem",
    color: "#666666",
    fontWeight: 500,
    marginBottom: "1em",
  },
  useGridItem: {
    padding: ".35em",
  },
  useItem: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  useIcon: {
    color: "#26a27b",
    marginRight: 5,
    marginTop: "-5px",
  },
  revolutionBackground: {
    backgroundImage: `url(${revolutionBackground})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
  },
  revolutionCard: {
    position: "absolute",
    boxShadow: theme.shadows[10],
    borderRadius: 15,
    padding: "10em",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "8em",
      paddingBottom: "8em",
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 0,
      width: "100%",
    },
  },
  learnButtonHero: {
    borderColor: dark,
    color: dark,
    borderWidth: 2,
    textTransform: "none",
    borderRadius: 50,
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "0.9rem",
    height: 45,
    width: 145,
  },
  subSectionHeader: {
    fontSize: "1.5rem",
    color: "#666666",
    fontWeight: 500,
  },
  subSectionBody: {
    color: "#616161",
  },
  contact: {
    padding: 0,
  },
  contactImage: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  infoBackground: {
    position: "relative",
    backgroundImage: `url(${contactbg})`,
    boxShadow: `inset 2000px 0 0 0 rgba(38, 162, 123, 0.78)`,
    borderColor: `rgba(0, 0, 0, 0.5)`,
    backgroundPosition: "center",
    // backgroundSize: "cover",
    backgroundRepeat: "repeat",
    height: "100%",
    width: "100vw",
  },
  benefitsBtn: {
    marginTop: "2rem",
    backgroundColor: primary,
    color: "#FFF",
    "&:hover": {
      backgroundColor: dark,
      color: "#fff",
    },
  },
}))

export default function SeoServicesMain({ keyword }) {
  const theme = useTheme()
  const classes = useStyles()

  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))

  let Box = ({ children, ...opts }) => (
    <BoxStyling {...opts}>{children}</BoxStyling>
  )

  let Service = ({ title, description, Icon = FaGem, nav = "/benefits/" }) => (
    <Grid item xs={12} sm={4} style={{ marginBottom: "1em" }}>
      <Link
        to={nav}
        style={{ textDecoration: "none" }}
        title={`Link to benefits page`}
      >
        <Box>
          <Icon size={30} alt={`${title} icon`} />
          <Typography variant="h6" gutterBottom style={{ marginTop: "1em" }}>
            {title}
          </Typography>
          <Typography variant="body1" align="center">
            {description}
          </Typography>
        </Box>
      </Link>
    </Grid>
  )

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.headerCtr}
      >
        <Grid item sm={12} md={8} lg={7}>
          <Container
            // maxWidth={matchesXS ? undefined : "md"}
            className={classes.headerSection}
          >
            <Grid item xs={12}>
              <Typography
                variant="h1"
                align={matchesSM ? "center" : "left"}
                className={classes.h1}
              >
                {keyword}
              </Typography>
              <Typography
                variant="h2"
                className={classes.h2}
                align={matchesSM ? "center" : "left"}
              >
                <span style={{ fontWeight: 500 }}>
                  Hire an expert to develop your next website.
                </span>
                <br />
                We use the latest, most advanced tech stack to get results from
                your website. Faster websites rank higher on Google and have
                higher sell-through. Choose Snappy Web Design to develop your
                website.
                <br />
                Headache free. Blazing fast. Mobile friendly.
              </Typography>
            </Grid>

            <Grid container justify={matchesXS ? "center" : undefined}>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  className={classes.contactBtn}
                  component={Link}
                  to="/pricing/"
                  elevation={24}
                >
                  Price calculator{" "}
                  <ArrowForwardIosIcon
                    fontSize="small"
                    className={classes.arrowIco}
                  />
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  className={classes.contactBtn}
                  component={Link}
                  to="/contact/"
                  elevation={24}
                >
                  Send me a quote{" "}
                  <ArrowForwardIosIcon
                    fontSize="small"
                    className={classes.arrowIco}
                  />
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item sm={12} md={4} lg={5}>
          <div className={classes.comicContainer}>
            <img
              src={undrawn}
              alt="Comic man watering flower"
              className={classes.comic}
            />
          </div>
        </Grid>
      </Grid>
      <Container maxWidth="md">
        <Grid container justify="center">
          <Grid item xs={12} style={{ paddingTop: "6rem" }}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.servicesHeader}>
                Our Services
              </Typography>
            </Grid>
            <Grid container justify="center" spacing={2}>
              <Service
                title="SEO"
                nav="/seo-services/"
                Icon={FaBolt}
                description={
                  "Our websites rank high on Google, because they're designed for Google."
                }
              />
              <Service
                nav="/websitedesign/"
                title="Web Design"
                description={
                  "Your brand is your story. Your website should be designed to tell it."
                }
              />
              <Service
                nav="/benefits/"
                title="Development"
                Icon={FaWrench}
                description={
                  "Fit your website with functionality and insights to increase revenue."
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Button
                  to="/benefits/"
                  component={Link}
                  className={classes.benefitsBtn}
                >
                  <FaLightbulb className="mr-1 mb-1" alt="lightbulb icon" />
                  View More Benefits
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "3rem" }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h3" className={classes.servicesHeader}>
                  Use Cases
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction={matchesSM ? "column" : "row"}>
                  <Grid item sm={12} md={6}>
                    <Grid container direction="column">
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: ".91rem",
                            marginLeft: 3,
                            fontWeight: "500",
                            color: "#30ad86",
                            textTransform: "uppercase",
                            paddingBottom: ".75em",
                          }}
                        >
                          By Category
                        </Typography>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaHandPointUp
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            E-Commerce
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaBloggerB className={classes.useIcon} size={20} />{" "}
                            Blogs
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaCameraRetro
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            Content & Media
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaGlobe className={classes.useIcon} size={20} />{" "}
                            Nonprofit Organizations
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaCommentDots
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            Discussion Boards
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaHandHoldingUsd
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            Services
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaStore className={classes.useIcon} size={20} />{" "}
                            Restaurant
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={6}
                    style={{ paddingTop: matchesSM ? "1.75em" : undefined }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: ".91rem",
                            marginLeft: 3,
                            fontWeight: "500",
                            color: "#30ad86",
                            textTransform: "uppercase",
                            paddingBottom: ".75em",
                          }}
                        >
                          By Type of Site
                        </Typography>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaHome className={classes.useIcon} size={20} />{" "}
                            Company Homepage
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaBlog className={classes.useIcon} size={20} />{" "}
                            Company Blog
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaArchway className={classes.useIcon} size={20} />{" "}
                            Custom Landing Pages
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaPhotoVideo
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            Professional Image Gallery
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaTicketAlt
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            Event Site
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaTools className={classes.useIcon} size={20} />{" "}
                            Client Management Site
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.useGridItem}>
                          <span className={classes.useItem}>
                            <FaChartArea
                              className={classes.useIcon}
                              size={20}
                            />{" "}
                            Data Visualization
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12}>
        <Grid item>
          {/*-----The Revolution Block -----*/}
          <Grid
            container
            style={{ height: "100em", marginTop: "12em" }}
            alignItems="center"
            justify="center"
          >
            <Card className={classes.revolutionCard}>
              <CardContent>
                <Grid
                  container
                  direction="column"
                  style={{ textAlign: "center" }}
                >
                  <Grid item>
                    <Typography
                      variant="h3"
                      gutterBottom
                      style={{ color: `${dark}` }}
                    >
                      Our Portfolio
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      style={{ marginBottom: "1rem" }}
                    >
                      We don't just want to tell you about our cutting-edge
                      technology. <br /> We want to show you. View our complete
                      portfolio now!
                    </Typography>
                    <Button
                      component={Link}
                      to="/portfolio/"
                      variant="outlined"
                      className={classes.learnButtonHero}
                    >
                      <span style={{ marginRight: 10 }}>Learn More</span>
                      <ButtonArrow width={15} height={15} fill={`${primary}`} />
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <div className={classes.revolutionBackground} />
          </Grid>
        </Grid>
      </Grid>
      <Container maxWidth="md">
        <Grid container justify="center">
          <Grid item xs={12} style={{ padding: "6rem 0" }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.subSectionHeader}
                >
                  Why Choose Snappy Web Design
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subSectionBody}>
                  In the new digital age of business, functional and beautiful
                  websites aren't a luxury, but a requirement. For most, the
                  steep prices of custom websites warrant them unrealistic.
                  Snappy Web Design is disrupting this reality by providing
                  fast, modern, aesthetic, and inexpensive websites to small
                  businesses owners, organizations, and creatives in the region.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ paddingTop: "3rem" }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.subSectionHeader}
                >
                  SEO
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subSectionBody}>
                  A websites position or 'ranking' on search engines is the
                  result of a mathematical formula measuring how well it stands
                  up to specific performance metrics and best practices. Some of
                  the most heavily weighted metrics are: page speed, mobile
                  usability/optimization, accessibility features, and a backend
                  that communicates relevant parts of webpages to "robo web
                  crawlers." Our websites perform exceptionally well on Google,
                  because they're designed for Google.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ paddingTop: "3rem" }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.subSectionHeader}
                >
                  Responsive Design
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subSectionBody}>
                  In 2020, 68% of all global web traffic originated from mobile
                  devices. With this number steadily increasing year over year,
                  mobile friendly sites are now a necessity. The top search
                  enginges agree, giving precedence to those websites which
                  provide seamless mobile experiences. Snappy excels in this
                  category - our websites intelligently and automatically load
                  smaller images and resources when viewed on smaller devices -
                  saving bandwidth, and increasing performance.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ paddingTop: "3rem" }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.subSectionHeader}
                >
                  Near-instant page loads
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subSectionBody}>
                  Our websites leverage the latest and greatest technology,
                  which loads critical parts of the page, and not a second
                  sooner than the user needs it. We further optimize our
                  websites by using intelligent tools that compress resources
                  without risk of diminished quality or functionality. All of
                  this results in a lightweight package with incredibly fast
                  load times. When navigating from page to page, everything just
                  feels...there. Avoid frustration from slow websites, get
                  Snappy.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ paddingTop: "3rem" }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.subSectionHeader}
                >
                  Analytics
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subSectionBody}>
                  Gain better insights into who your prospective users/customers
                  are, and how they engage with your website. View and analyze
                  behavioral trends and traffic funnels. With Snappy, you have
                  greater access to relevant data that can provide you with
                  fruitful insights.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" className={classes.contact}>
        <Grid
          container
          style={{ height: "40em" }}
          alignItems="center"
          direction="row"
          className={classes.infoBackground}
        >
          <Grid
            item
            container
            style={{
              textAlign: matchesXS ? "center" : "inherit",
            }}
            direction={matchesXS ? "column" : "row"}
          >
            <Grid
              item
              sm
              style={{ marginLeft: matchesXS ? 0 : matchesSM ? "2em" : "5em" }}
            >
              <Grid
                container
                style={{ marginBottom: matchesXS ? "10em" : 0 }}
                direction="column"
              >
                <Typography
                  variant="h6"
                  style={{ color: "white", fontSize: "2.5rem" }}
                >
                  About Us
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "white",
                    fontSize: "1.5rem",
                    paddingBottom: "1em",
                  }}
                >
                  Let's get personal.
                </Typography>
                <Grid item>
                  <Button
                    component={Link}
                    to="/about/"
                    variant="outlined"
                    style={{ color: "white", borderColor: "white" }}
                    className={classes.learnButton}
                  >
                    <span style={{ marginRight: 10 }}>Learn More</span>
                    <FaArrowRight width={10} height={10} fill="white" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm
              style={{
                marginRight: matchesXS ? 0 : matchesSM ? "2em" : "5em",
                textAlign: matchesXS ? "center" : "right",
              }}
            >
              <Grid container direction="column">
                <Typography
                  variant="h6"
                  style={{ color: "white", fontSize: "2.5rem" }}
                >
                  Contact Us
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "white",
                    fontSize: "1.5rem",
                    paddingBottom: "1em",
                  }}
                >
                  Say Hello!{" "}
                  <span role="img" aria-label="waving hand">
                    👋🏼
                  </span>
                </Typography>
                <Grid item>
                  <Button
                    component={Link}
                    to="/contact/"
                    variant="outlined"
                    style={{ color: "white", borderColor: "white" }}
                    className={classes.learnButton}
                  >
                    <span style={{ marginRight: 10 }}>Get in touch</span>
                    <FaArrowRight width={10} height={10} fill="white" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
